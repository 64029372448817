<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-enterprise-list' }">Enterprises</router-link>
                </p>
                </v-col>
            </v-row> -->

            <!-- TODO: add an "enterprise overview" acard with other things... need link to manage enterprise at unicorn springs, at least. -->
            <!-- <v-row justify="center" class="pt-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="2" class="my-6">
                        <v-app-bar color="indigo" dark flat dense>
                            <v-app-bar-title>Enterprise overview</v-app-bar-title>
                            <v-spacer/>
                        </v-app-bar>
                        <v-card-text>
                            <a href="#" @click="redirectToUnicornSprings">Manage enterprise billing</a>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row> -->

            <!-- TODO: add a "users" card with list of admin users linked to the enterprise; or maybe that goes in unicorn springs?? -->

            <v-row justify="center" class="pt-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="2" class="my-6">
                        <v-app-bar color="indigo" dark flat dense>
                            <v-app-bar-title>Identity Realms</v-app-bar-title>
                            <v-spacer/>
                            <v-btn icon :to="{ name: 'cryptium_id-create-realm', params: { enterpriseId: this.$route.params.enterpriseId } }">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
                            </v-btn>
                        </v-app-bar>
                        <RealmList @selected="onSelectRealm" create/>
                    </v-card>
                </v-col>
            </v-row>

        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import RealmList from '@/components/cryptium_id/RealmList.vue';

export default {
    components: {
        RealmList,
    },
    data: () => ({
        enterprise: null,
        error: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        name() {
            return this.enterprise?.name ?? 'Unknown';
        },
    },
    methods: {
        async loadEnterprise() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadEnterprise: true });
                const response = await this.$client.enterprise(this.$route.params.enterpriseId).self.get();
                console.log(`enterprise/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.enterprise = response;
                } else {
                    // TODO: redirect back to enterprise list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load enterprise', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadEnterprise: false });
            }
        },
    },
    mounted() {
        this.loadEnterprise();
    },
};
</script>
